@import "../../assets/scss/variable";
.input__wrapper {
    border-radius: 12px;
    background: #2c2d3b;
    width: 90%;
    height: 60px;
    overflow: hidden;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;


    input,
    textarea {
        height: 95%;
        width: 100%;
        padding: 0 2rem;
        background-color: transparent;
        outline: none;
        border: none;
        color: #ffffff;
        &::placeholder {
            color: #808189;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
        }
        &[type="password"] {
            padding: 0 3rem 0 2rem;
        }
        
        &::-webkit-calendar-picker-indicator {
            filter: invert(1);
        }
        &:disabled {
            color: #808189;
            cursor: not-allowed;
        }
    }
    .inputIcon {
        position: absolute;
        right: 0.8rem;
        font-size: 24px;
        cursor: pointer;
        top: 30%;
        color: #808189;
    }
    label {
        position: absolute;
        top: 30%;
        left: 2rem;
        color: #808189;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        transition: all 0.5s ease-in-out;
    }
    label.active {
        top: 6%;
        font-size: 10px;
        transition: all 0.5s ease-in-out;
    }

    &.CompetitionRegistration__search__input {
        border-radius: 60px;
        margin: 0;
        width: 360px;
        input {
            &::placeholder {
                color: $dashboard-text-color;
            }
        }
        &.CompetitionStudents {
            background-color: #3b3e58;
        }
    }
    &.CompetitionRegistration__model__input {
        width: 100%;
    }
    &.Registration__form__input {
        border-radius: 12px;
        background: #242328;
        width: 100%;
        &::placeholder {
            color: #78797b;
        }
        &.textarea {
            min-height: 122.368px;
            textarea {
                resize: none;
                padding-top: 1rem;
            }
        }
    }
    &.input__checkbox__invitations__methods {
        width: 50px;
        background-color: transparent;
        input {
            width: 75%;
        }
    }
    &.product__input{
        background-color: transparent;
        width: min(21px,21px);
        height: min(21px,21px);
        input{
            width: 21px;
            height: 100%;
            padding: 0;
        }
        background-color: red;
    }
}
.input__error {
    width: 90%;
    margin-top: -0.8rem;
    margin-bottom: 0.8rem;
}
